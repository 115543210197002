
























import Vue from 'vue';
import BasketResourceViewModel from '@/src/services/viewModel/resource/BasketResourceViewModel';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
    saveFunction: { type: Function, default: () => {} },
    proceedFunction: { type: Function, default: () => {} },
  },
  data: () => ({
  }),
  computed: {
    paymentAmount(): number {
      return this.innerValue.orderBasket.grossTotal;
    },
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onPayed(data: BasketResourceViewModel) {
      if (!this.innerValue.orderBasket.onlinePayment.transactionId) {
        this.innerValue.orderBasket.paymentMethod = data.paymentMethod;
        this.innerValue.orderBasket.onlinePayment.fromModel(data.onlinePayment);
        this.saveFunction!();
      }
      this.proceedFunction!();
    },
  },
});
